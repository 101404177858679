import React, { useEffect, useState, useRef } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import Cookies from "js-cookie";

import Layout from "../components/ui/Layout";
import Hero from "../components/Hero";
import Section1 from "../components/Section-1";
import AboutUs from "../components/AbousUs";
import Quote from "../components/Quote";
import Contact from "../features/Contact";
import Stats from "../components/Stats";

export default function HomePage() {
  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add('cc--darkmode');
    
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "box wide",
          position: "bottom left",
          equalWeightButtons: false,
          flipButtons: true,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        functionality: {},
        analytics: {},
      },
      language: {
        default: "it",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "This website uses cookies",
              description:
                "We use cookies to ensure you get the best experience on our website. Please review our Privacy Policy for more information and/or to change your cookie settings at any time. If you continue to use this site, you consent to our use of cookies.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
              footer:
                '<a href="https://www.ferromarrocco.com/privacy-policy">Privacy Policy</a>\n<a href="https://www.ferromarrocco.com/terms-of-service">Terms and conditions</a>',
            },
            preferencesModal: {
              title: "Consent Preferences Center",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Cookie Usage",
                  description:
                    "We use cookies to ensure you get the best experience on our website. Please review our Privacy Policy for more information and/or to change your cookie settings at any time.",
                },
                {
                  title:
                    'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                  description:
                    "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Functionality Cookies",
                  description:
                    "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages.",
                  linkedCategory: "functionality",
                },
                {
                  title: "Analytics Cookies",
                  description:
                    "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                    'For any query in relation to my policy on cookies and your choices, please <a class="cc__link" href="/contact">contact us</a>.',
                },
              ],
            },
          },
          it: {
            consentModal: {
              title: "Questo sito web utilizza i cookie",
              description:
                "Utilizziamo i cookie per garantire che tu possa avere la migliore esperienza sul nostro sito web. Per ulteriori informazioni e/o per modificare le tue impostazioni sui cookie in qualsiasi momento, consulta la nostra Informativa sulla privacy. Se continui a utilizzare questo sito, acconsenti al nostro utilizzo dei cookie.",
              acceptAllBtn: "Accetta tutto",
              acceptNecessaryBtn: "Rifiuta tutto",
              showPreferencesBtn: "Gestisci preferenze",
              footer:
                '<a href="https://www.ferromarrocco.com/privacy-policy">Informativa sulla privacy</a>\n<a href="https://www.ferromarrocco.com/terms-of-service">Termini e condizioni</a>',
            },
            preferencesModal: {
              title: "Centro preferenze per il consenso",
              acceptAllBtn: "Accetta tutto",
              acceptNecessaryBtn: "Rifiuta tutto",
              savePreferencesBtn: "Salva le preferenze",
              closeIconLabel: "Chiudi la finestra",
              serviceCounterLabel: "Servizi",
              sections: [
                {
                  title: "Utilizzo dei Cookie",
                  description:
                    "Utilizziamo i cookie per garantire che tu possa avere la migliore esperienza sul nostro sito web. Per ulteriori informazioni e/o per modificare le tue impostazioni sui cookie in qualsiasi momento, consulta la nostra Informativa sulla privacy.",
                },
                {
                  title:
                    'Cookie Strettamente Necessari <span class="pm__badge">Sempre Attivati</span>',
                  description:
                    "Questi cookie sono necessari per il funzionamento del sito web e non possono essere disattivati nei nostri sistemi. Di solito vengono impostati solo in risposta ad azioni da te effettuate che costituiscono una richiesta di servizi, come l'impostazione delle preferenze sulla privacy, l'accesso o la compilazione di moduli.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Cookie di Funzionalità",
                  description:
                    "Questi cookie consentono al sito web di offrire funzionalità e personalizzazioni avanzate. Possono essere impostati da noi o da fornitori terzi i cui servizi abbiamo aggiunto alle nostre pagine.",
                  linkedCategory: "functionality",
                },
                {
                  title: "Cookie Analitici",
                  description:
                    "Questi cookie ci consentono di contare le visite e le fonti di traffico in modo da poter misurare e migliorare le prestazioni del nostro sito. Ci aiutano a sapere quali pagine sono le più e le meno popolari e a vedere come si muovono i visitatori nel sito.",
                  linkedCategory: "analytics",
                },
                {
                  title: "Ulteriori informazioni",
                  description:
                    'Per qualsiasi domanda in relazione alla mia politica sui cookie e alle tue scelte, ti preghiamo di <a class="cc__link" href="/contact">contattarci</a>.',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  // useEffect(() => {
  //   const mailingListCookie = Cookies.get('mailingListEmail');
  //   if (!mailingListCookie) {
  //     setTimeout(() => {
  //       setPopupVisible(true);
  //     }, 1000);
  //   } else {
  //     console.log("[Cookies] Mailing List ID: "+ mailingListCookie)
  //   }
  // }, []);

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleSubmitSubscribe = (email) => {
    subscribeToMailingList(email);
    sendCodeEmail(email);
  }

  async function subscribeToMailingList(email) {
    const response = await fetch('https://andromeda-mail-list.vercel.app/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    });
  
    if (response.ok) {
      if (response.status === 201) {
        handleClosePopup();
        Cookies.set('mailingListEmail', email, { expires: 3500 });
      } else {
        const data = await response.json();
        alert(`Error subscribing to mailing list: ${data.error}`);
      }
    } else {
      alert(`Error subscribing to mailing list: ${response.statusText}`);
    }
  }

  async function sendCodeEmail(email) {
    const response = await fetch('https://andromeda-backend.vercel.app/send-ferromarrocco-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_FORM_KEY,
      },
      body: JSON.stringify({ email, client_id: process.env.REACT_APP_FORK_SECRET}),
    });

    if (!response.ok) {
      alert(`Error sending code email: ${response.statusText}`);
    }
  }

  return (
    <>
      <section className="relative bg-[#212121] h-[80vh]">
        <meta name="description" content="Ferro Marrocco - Artisti del Ferro" />
        <Hero />
      </section>
      <section className="w-full h-screen bg-black bg-opacity-50 z-10">
        <Layout>
          <Section1 />
          <AboutUs />
          <Stats />
          <Quote />
          <Contact />
        </Layout>
      </section>
      <Popup isVisible={isPopupVisible} onClose={handleClosePopup} onSubscribe={handleSubmitSubscribe} />
    </>
  );
}

function Popup({ isVisible, onClose, onSubscribe }) {
  const [email, setEmail] = useState('');
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      onSubscribe(email);
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className={`fixed inset-0 flex z-50 items-center justify-center bg-black bg-opacity-70 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div ref={popupRef} className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md transform transition-transform duration-300 ${isVisible ? 'scale-100' : 'scale-75'}`}>
        <h2 className="text-xl font-bold mb-4">Iscriviti gratuitamente al nostro gruppo</h2>
        <p className="mb-4">Iscriviti subito, e riceverai in omaggio il 5% di sconto</p>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <input 
            type="email" 
            placeholder="Inserisci la tua email" 
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            value={email}
            onChange={handleInputChange}
            required
          />
          <button
            type="submit" 
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
          >
            Iscriviti
          </button>
        </form>
        <button
          onClick={() => onClose(email)}
          className="mt-4 text-gray-500 hover:text-gray-800"
        >
          Close
        </button>
      </div>
    </div>
  );
}
