import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneVolume, FaWhatsapp, FaInstagram } from "react-icons/fa6";
import { CiFacebook } from "react-icons/ci";
import { MdEmail } from "react-icons/md";

const Contact = ({ contactTheme }) => {
  if (contactTheme !== "dark") {
    function sendContact (e) {
      e.preventDefault();
      const fullname = document.getElementById("fullname").value;
      const email = document.getElementById("email").value;
      const message = document.getElementById("message").value;

      if(fullname && email && message) {
        console.log(fullname, email, message);
        sendContactApi(fullname, email, message);
      } else {
        alert("Per favore, compila tutti i campi.");
      }
    }

    function sendContactApi(fullname, email, message) {
      console.log("Sending")
      console.log(fullname, email, message);
      fetch("https://andromeda-backend.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_FORM_KEY,
        },
        body: JSON.stringify({ fullname, email, message, client_id: process.env.REACT_APP_FORK_SECRET}),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if(data.ok) {
            alert("Messaggio inviato con successo.");
          } else {
            alert("Errore nell'invio del messaggio. Riprovare o contattateci telefonicamente.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

        document.getElementById("fullname").value = "";
        document.getElementById("email").value = "";
        document.getElementById("message").value = "";
    }
    
    return (
      <section id="contact" className="w-full bg-white z-10">
        <div className="max-w-[1500px] mx-auto">
          <div className="flex flex-col md:flex-row justify-center">
            <div className="w-full md:w-1/3 p-8 text-[#333333]">
              <h1 className="text-[#406580] text-[12px] font-bold tracking-widest">MARROCCO GROUP SRLS</h1>
              <h2 className="text-4xl font-bold">Indirizzo</h2>
              <p className="mt-4">
                Via Luigi Einaudi, 10, 00040 Marino RM, Italia
              </p>
              <div className="flex items-center mt-8">
                <div className="mr-4 text-[#406580] text-2xl">
                  <a href="tel:+3423971162">
                    <FaPhoneVolume />
                  </a>
                </div>
                <div>
                  <h3 className="text-[12px] tracking-widest">PREVENTIVI</h3>
                  <p className="text-[#406580]">+39 063 105 4072</p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <div className="mr-4 text-[#406580] text-2xl">
                  <a href="mailto:marroccogroupsrls@gmail.com">
                    <MdEmail />
                  </a>
                </div>
                <div>
                  <h3 className="text-[12px] tracking-widest">DOMANDE</h3>
                  <a className="text-[#406580]" href="mailto:marroccogroupsrls@gmail.com">marroccogroupsrls@gmail.com</a>
                </div>
              </div>
              {/* Social Media Icons */}
              <div className="flex items-center mt-8">
                <Link to="tel:+3423971162">
                  <div className="mr-4 text-[#406580] text-2xl">
                    <FaWhatsapp />
                  </div>
                </Link>
                <Link to="https://www.instagram.com/marroccogroup/">
                  <div className="mr-4 text-[#406580] text-2xl">
                    <FaInstagram />
                  </div>
                </Link>
                <Link to="https://www.facebook.com/people/Marrocco-Group/61552063162756/?sk=reels_tab">
                  <div className="mr-4 text-[#406580] text-2xl">
                    <CiFacebook />
                  </div>
                </Link>
              </div>
            </div>

            <div className="w-full md:w-2/3 p-8">
              <div className="relative">
                <div className="absolute inset-0 border-2 border-[#1d506b] rounded-lg translate-x-2 -translate-y-2 transform pointer-events-none z-0"></div>
                <form className="mt-2 bg-[#406580] p-4 rounded-lg relative z-10">
                  <div className="relative flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2">
                      <label className="text-white" htmlFor="name">
                        Nome Completo
                      </label>
                      <input
                        className="w-full mt-2 p-2 rounded-md"
                        type="text"
                        id="fullname"
                        name="name"
                        placeholder="John Doe"
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:ml-4">
                      <label className="text-white" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="w-full mt-2 p-2 rounded-md"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="example@test.com"
                      />
                    </div>
                  </div>
                  <div className="mt-4 relative">
                    <label className="text-white" htmlFor="message">
                      Messaggio
                    </label>
                    <textarea
                      className="w-full mt-2 p-2 rounded-md"
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="il tuo messaggio"
                    ></textarea>
                  </div>
                  <button className="mt-4 bg-gray-200 text-black text-bold py-2 px-5 rounded-full border-2 border-white relative z-10" onClick={sendContact}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    function sendContact (e) {
      e.preventDefault();
      const fullname = document.getElementById("fullname").value;
      const email = document.getElementById("email").value;
      const message = document.getElementById("message").value;

      if(fullname && email && message) {
        sendContactApi({ fullname, email, message });
      } else {
        alert("Per favore, compila tutti i campi.");
      }
    }

    function sendContactApi({ fullname, email, message }) {
      fetch("https://andromeda-backend.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_FORM_KEY,
        },
        body: JSON.stringify({ fullname, email, message, client_id: process.env.REACT_APP_FORK_SECRET}),
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.ok) {
            alert("Messaggio inviato con successo.");
          } else {
            alert("Errore nell'invio del messaggio. Riprovare o contattateci telefonicamente.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

        document.getElementById("fullname").value = "";
        document.getElementById("email").value = "";
        document.getElementById("message").value = "";
    }
    return (
      <section id="contact" className="w-full bg-[#1f2127] z-10">
        <div className="max-w-[1500px] mx-auto">
          <div className="flex flex-col md:flex-row justify-center">
            <div className="w-full md:w-1/3 p-8 text-white">
              <h1 className="text-[#406580] text-[12px] font-bold tracking-widest">MARROCCO GROUP SRLS</h1>
              <h2 className="text-4xl font-bold">Indirizzo</h2>
              <p className="mt-4">
                Via Luigi Einaudi, 10, 00040 Marino RM, Italia
              </p>
              <div className="flex items-center mt-8">
                <div className="mr-4 text-[#406580] text-2xl">
                  <FaPhoneVolume />
                </div>
                <div>
                  <h3 className="text-[12px] tracking-widest">PREVENTIVI</h3>
                  <p className="text-[#406580]">+39 063 105 4072</p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <div className="mr-4 text-[#406580] text-2xl">
                  <MdEmail />
                </div>
                <div>
                  <h3 className="text-[12px] tracking-widest">DOMANDE</h3>
                  <a className="text-[#406580]" href="mailto:marroccogroupsrls@gmail.com">marroccogroupsrls@gmail.com</a>
                </div>
              </div>
              {/* Social Media Icons */}
              <div className="flex items-center mt-8">
                <Link to="tel:+3423971162">
                  <div className="mr-4 text-[#406580] text-2xl">
                    <FaWhatsapp />
                  </div>
                </Link>
                <Link to="https://www.instagram.com/marroccogroup/">
                  <div className="mr-4 text-[#406580] text-2xl">
                    <FaInstagram />
                  </div>
                </Link>
                <Link to="https://www.facebook.com/people/Marrocco-Group/61552063162756/?sk=reels_tab">
                  <div className="mr-4 text-[#406580] text-2xl">
                    <CiFacebook />
                  </div>
                </Link>
              </div>
            </div>
            <div className="w-full md:w-2/3 p-8">
              <div className="relative">
                <div className="absolute inset-0 border-2 border-[#1d506b] rounded-lg translate-x-2 -translate-y-2 transform pointer-events-none z-0"></div>
                <form className="mt-2 bg-[#406580] p-4 rounded-lg relative z-10">
                  <div className="relative flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2">
                      <label className="text-white" htmlFor="name">
                        Nome Completo
                      </label>
                      <input
                        className="w-full mt-2 p-2 rounded-md"
                        type="text"
                        id="fullname"
                        name="name"
                        placeholder="John Doe"
                      />
                    </div>
                    <div className="w-full md:w-1/2 md:ml-4">
                      <label className="text-white" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="w-full mt-2 p-2 rounded-md"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="example@test.com"
                      />
                    </div>
                  </div>
                  <div className="mt-4 relative">
                    <label className="text-white" htmlFor="message">
                      Messaggio
                    </label>
                    <textarea
                      className="w-full mt-2 p-2 rounded-md"
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="il tuo messaggio"
                    ></textarea>
                  </div>
                  <button className="mt-4 bg-gray-200 text-black text-bold py-2 px-5 rounded-full border-2 border-white relative z-10" onClick={sendContact}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Contact;